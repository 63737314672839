<template>
  <section>
    <div class="filterationBox">
      <header>Manage My Account</header>
      <ul class="filterBox">
        <li>
          <router-link to="/account">My Profile</router-link>
        </li>
        <li>
          <router-link to="/changePassword">Change Password</router-link>
        </li>
        <li>
          <router-link to="/address">Address Book</router-link>
        </li>

        <!-- <li>
          <router-link to="/mypayment">My Payment Options</router-link>
        </li> -->
      </ul>
    </div>
    <div class="filterationBox">
      <header>My Orders</header>
      <ul class="filterBox">
        <li>
          <router-link to="/orders">My Purchase</router-link>
        </li>
        <li>
          <router-link to="/ordersprocess">My Process</router-link>
        </li>
        <li>
          <router-link to="/ordercomplete">My Complete</router-link>
        </li>
        <li>
          <router-link to="/ordersreturn">My Return</router-link>
        </li>
        <li>
          <router-link to="/orderscancel">My Cancellation</router-link>
        </li>
        <li>
          <router-link to="/ordersexchange">My Exchanged</router-link>
        </li>
      </ul>
    </div>
    <div class="filterationBox">
      <header>My Bookings</header>
      <ul class="filterBox">
        <li>
          <router-link to="/orders">My Purchase</router-link>
        </li>
        <li>
          <router-link to="/ordersprocess">My Process</router-link>
        </li>
        <li>
          <router-link to="/ordercomplete">My Complete</router-link>
        </li>
        <li>
          <router-link to="/ordersreturn">My Return</router-link>
        </li>
        <li>
          <router-link to="/orderscancel">My Cancellation</router-link>
        </li>
        <li>
          <router-link to="/ordersexchange">My Exchanged</router-link>
        </li>
      </ul>
    </div>
    <div class="filterationBox">
      <header>My Review</header>
      <ul class="filterBox">
        <li>
          <router-link to="/tobereviewed">To Be Reviewed</router-link>
        </li>
        <li>
          <router-link to="/reviewed">Reviewed</router-link>
        </li>
      </ul>
    </div>

    <div class="filterationBox">
      <header>Sell On EVDrive</header>
      <ul class="filterBox">
        <li>
          <router-link to="/myvehicles">My Vehicle List</router-link>
        </li>
        <li>
          <router-link to="/sellyourcar">Sell My Vehicle</router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "Navigation",
};
</script>

<style lang="scss" scoped>
.filterationBox {
  border: 1px solid #efefef;
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
}
</style>